"use client"
import Button from '@/app/ui/button'
import { getIcon } from '@/lib/get-icon'
import Input from '@/ui/input'
import { Dialog, Transition } from '@headlessui/react'
import React, { Fragment, useState } from 'react'
import commonIcons from '../icons/common-icons'
import bannerIcons from '../icons/banner-icons'
import Image from 'next/image'
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify'
import { useBookDemoMutation} from '@/framework/rest/contact/contact.query'
import { useRouter } from 'next/navigation';

const contactSchema = yup.object().shape({
    name: yup.string().required("Name is required"),
    email: yup.string().required("Email is required").email("Email is not valid"),
    business_name: yup.string().required("Business name is required"),
    phone: yup.number().required("Phone is required").typeError("Phone must be a number"),
    industry: yup.string().required("Industry is required"),
})


export default function AutoBookDemo({ isRequestOpen, closeRequestModal }: any) {
    const router = useRouter();
    const { register, handleSubmit, reset, formState: { errors } } = useForm({ resolver: yupResolver(contactSchema) });
    const { mutate: contact, isIdle: isLoading } = useBookDemoMutation();
    
    const [enabled, setEnabled] = useState(true)
    const [token, setTokaen] = useState("");
   

    const onSubmit = (data: any) => {
        // const token = captchaRef.current.getValue();
        contact({ ...data, phone : data?.phone.toString(), page_type:"schedule_a_demo", token: token }, {
            onSuccess: (d) => {
                //toast.success(d?.data?.message);
                toast.success("Demo request sent successfully");
                router.push("/")
                reset();
                //setToken("")
                setEnabled(true)
                closeRequestModal()
            },
            onError : (error:any) => {
                toast.success(error?.response?.data?.message);
            }
        });
    };

    return (
        <div className="fixed top-0 w-full max-w-sm px-4 z-50">
            <Transition appear show={isRequestOpen} as={Fragment}>
                <Dialog as="div" className="relative z-50" onClose={closeRequestModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black/25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="w-full flex h-screen items-center justify-center p-0 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="lg:w-4/6 xl:w-3/6 bg-white xs:h-[94vh] xs:w-[97%] sm:w-[95%] overflow-y-auto transform p-0 text-left align-middle transition-all">
                                    <span className='absolute right-5 top-5 xs:top-1 xs:right-2 xs:w-4 xs:h-4 cursor-pointer' onClick={closeRequestModal}>{getIcon({ iconList: commonIcons, iconName: 'CloseIcon', className:'w-full' })}</span>
                                    <Dialog.Title as="h3" className="bg-fuchsia-950 flex flex-col p-8 xs:py-8 xs:px-5 xs:w-screen xs:items-end">
                                        <div className='xs:w-[80%]'>
                                        <div className="text-white text-[32px] xs:text-2xl xs:text-right font-bold">Not sure where to start?</div>
                                        <div className="text-white text-[22px] xs:text-base xs:pl-14 xs:text-right font-medium">Schedule your omnichannel live demo now</div>
                                        </div>
                                    </Dialog.Title>
                                    <div className='p-8 xs:p-0 grid grid-cols-2 xs:grid-cols-1'>
                                        <form className="col-span-1 xs:relative z-50 xs:p-8 xs:bg-white" onSubmit={handleSubmit(onSubmit)}>
                                            <div className='flex flex-col xs:flex-wrap gap-2 mb-6 xs:mb-4'>
                                                <Input variant='iconBox' iconName='UserIcon' labelKey='Full Name *' placeholder='Your Name' className='w-full' {...register("name")} 
                                                //errorKey={errors.name?.message}
                                                 />
                                            </div>
                                            
                                            <div className='flex flex-col xs:flex-wrap gap-2 my-6 xs:my-4'>
                                                <Input variant='iconBox' iconName='MailIcon' labelKey='Business Email *' placeholder='Email Id' className='w-full' {...register("email")} 
                                               // errorKey={errors.email?.message}  
                                                />
                                            </div>
                                            
                                            <div className='flex flex-col xs:flex-wrap gap-2 my-6 xs:my-4'>
                                                <Input variant='iconBox' iconName='BuildingIcon' labelKey='Business Name *' placeholder='Business Name' className='w-full' {...register("business_name")} 
                                              //  errorKey={errors.business_name?.message}  
                                                />
                                            </div>
                                            <div className='flex flex-col xs:flex-wrap gap-2 my-6 xs:my-4'>
                                                <Input variant='iconBox' iconName='IndianFlagIcon' labelKey='Phone Number *' placeholder='Phone Number' pattern="[1-9]{1}[0-9]{9}" title={errors.phone?.message} maxLength={10} className='w-full' {...register("phone")} 
                                               // errorKey={errors.phone?.message} 
                                               />
                                                {/* <label className="text-base opacity-90 text-neutral-700 font-semibold">Phone Number *</label>
                                                <SelectCountryCode /> */}
                                            </div>
                                            <div className='flex flex-col xs:flex-wrap gap-2 my-6 xs:my-4'>
                                                <Input variant='iconBox' iconName='IndustryIcon' labelKey='industry *' placeholder='Industry' className='w-full' {...register("industry")} 
                                              //  errorKey={errors.business_name?.message}  
                                                />
                                            </div>
                                            <div className='text-right w-full'>
                                                <Button className='w-full' type="submit">Schedule a Demo  {getIcon({ iconList: bannerIcons, iconName: "BookingIcon" })}</Button>
                                            </div>
                                        </form>
                                        <div className='col-span-1 flex'>
                                            <Image src={'/images/enquery-guy.webp'} loading='lazy' sizes='2x3' alt='Invenzo Enquey' width={400} height={630} className='absolute right-10 sm:-right-28 bottom-0 h-[93%] xs:scale-x xs:scale-x-[-1] xs:top-0 xs:h-[35%] xs:left-0 flex-shrink-0 max-h-full max-w-full w-auto ' />
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </div>
    )
}
